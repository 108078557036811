import axios from 'axios';


const content_generator_core_host = process.env.REACT_APP_API_URL;

export const getModels = async (text) => {
  try {
    const response = await axios.get(`${content_generator_core_host}/v1/film-wizard/models`);
    return response.data.models;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};
