import axios from 'axios';


const content_generator_core_host = process.env.REACT_APP_API_URL;

export const getScenes = async (videoId, modelId) => {
  try {
    const response = await axios.post(`${content_generator_core_host}/v1/film-wizard/video/${videoId}/scenes`, {
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};

export const getScene = async (videoId, sceneId, modelId) => {
  try {
    const response = await axios.put(`${content_generator_core_host}/v1/film-wizard/video/${videoId}/scene`, {
      "scene_index": sceneId,
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};

export const generateFinalClip = async (videoId) => {
  try {
    const response = await axios.post(`${content_generator_core_host}/v1/film-wizard/video/${videoId}`);
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};